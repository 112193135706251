import axios, { Method } from "axios";
import { msalInstance } from "..";
import { loginRequest } from "../authConfig";
import { standardResponse } from "./standard-response.model";
import jwt from "jsonwebtoken";
import moment from "moment";
import {
  DATE_DIFF_TYPE,
  DATE_TIME_FORMAT,
  getDateDifference,
  TOKEN_EXPIRY_DIFF,
} from "../utils";

const getSilentTokenAndStoreIt = async () => {

  const decodedHeader = decodedJwt();

  const expireDate = new Date(decodedHeader.payload.exp * 1000);
  const currentDate = new Date();

  const expiryMoment = moment(expireDate).format(
    DATE_TIME_FORMAT.REQUEST_DATE_TIME
  );
  const currentMoment = moment(currentDate).format(
    DATE_TIME_FORMAT.REQUEST_DATE_TIME
  );

  const difference = getDateDifference(
    DATE_DIFF_TYPE.SECONDS,
    currentMoment,
    expiryMoment
  );

  if (typeof difference !== "undefined") {
    if (difference <= TOKEN_EXPIRY_DIFF) {
      const token = await msalInstance.acquireTokenSilent({
        scopes: loginRequest.scopes,
      });
      localStorage.setItem("idToken", token.idToken);
    }
  }
};


export const decodeJwt = (token: string): any | null =>
  jwt.decode(token, { complete: true });

  export const decodedJwt = (): any | null =>
  decodeJwt(localStorage.getItem("idToken") ?? "");

export const getBearerToken = async () => {
  const account = msalInstance.getActiveAccount();

  if (!account) {
    throw Error(
      "No active account! Verify a user has been signed in and setActiveAccount has been called."
    );
  }

  await getSilentTokenAndStoreIt();

  return `Bearer ${localStorage.getItem("idToken")}`;
};

export const returnPromise = async (
  requestedURL: string,
  httpMethod: Method,
  data?: any
) => {
  try {
    const res = await BaseService({
      url: requestedURL,
      method: httpMethod,
      ...(data ? { data } : null)
    });
    return standardResponse(res);
  } catch (e) {
    return Promise.reject(e);
  }
};

const BaseService = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // url = base url + request url
  timeout: 60000,
});

BaseService.interceptors.request.use(
  async (config: any) => {
    const isFormData = config.data && config.data.isFormData === true;
    try {
      config.headers["Authorization"] = await getBearerToken();
      config.headers["Access-Control-Allow-Origin"] = "*";
      if (isFormData) {
        config.headers["Content-Type"] = "multipart/form-data";
      }
  } catch (error) {
    //window.location.reload();
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptors Customize based on your need
BaseService.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    switch (error.response && error.response.status) {
      case 401:
        localStorage.clear();
        // msalInstance.logoutRedirect({
        //   postLogoutRedirectUri: "/",
        // });
        break;
      // localStorage.removeItem("idToken");
      // getSilentTokenAndStoreIt();

      // return BaseService(originalConfig);
      // Authorization Failed Response can add other status codes here to manage error Logging
      case 403:
        break;
      default:
        break;
    }
    return Promise.reject(error.response);
  }
);

export default BaseService;
