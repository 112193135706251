import moment, { Moment } from "moment-timezone";
import { ACTION, DARK_THEME_COLOR, DATE_DIFF_TYPE, DATE_FILTER_CRITERIA, DATE_TIME_FORMAT, LOCALSTORAGE_KEY, LOCAL_STORAGE_KEYS, SYSTEM_CONFIG_KEYS } from ".";
import json2csvExport from "json-to-csv-export";
import { ZoomPluginOptions } from "chartjs-plugin-zoom/types/options";
import { decodedJwt } from "../services/base-service";

const momentFields = ["modified_on", "timestamp"];
const isMomentField = (field: string) => momentFields.includes(field);

export const getEnvVariables = () => {
  debugger;
  const {
    REACT_APP_CLIENT_ID,
    REACT_APP_AUTHORITY,
    REACT_APP_KNOWNAUTHORITIES,
    REACT_APP_PASSWORDRESETURL,
  } = process.env;

  return {
    REACT_APP_CLIENT_ID,
    REACT_APP_AUTHORITY,
    REACT_APP_KNOWNAUTHORITIES,
    REACT_APP_PASSWORDRESETURL,
  };
};

export const viewMode = (mode: string) => mode.toUpperCase() === ACTION.VIEW;

const getPreviuousMonthStartDate = (monthBefore: number) => {
  return moment()
    .subtract(monthBefore, "months")
    .startOf("month")
    .format(DATE_TIME_FORMAT.REQUEST_DATE_TIME);
};

const getPreviuousMonthEndDate = () => {
  return moment()
    .subtract(1, "months")
    .endOf("month")
    .format(DATE_TIME_FORMAT.REQUEST_DATE_TIME);
};

export const getDatesAsPerSelectedCriteria = (filterCriteria: string, from_date?: Moment | string,
  to_date?: Moment | string,) => {
  const today = new Date();
  let fDate;
  let tDate;
  tDate = moment(today).format(DATE_TIME_FORMAT.REQUEST_DATE_TIME);
  if (filterCriteria == DATE_FILTER_CRITERIA.LAST_MONTH) {
    fDate = getPreviuousMonthStartDate(1);
    tDate = getPreviuousMonthEndDate();
  } else if (filterCriteria == DATE_FILTER_CRITERIA.LAST_3_MONTHS) {
    fDate = getPreviuousMonthStartDate(2);
    tDate = new Date().setDate(today.getDate())
  }
  else if (filterCriteria == DATE_FILTER_CRITERIA.LAST_30_DAYS) {
    fDate = new Date().setDate(today.getDate() - 30)
    tDate = new Date().setDate(today.getDate())
  }
  else if (filterCriteria == DATE_FILTER_CRITERIA.CURRENT_MONTH) {
    fDate = moment()
      .startOf("month")
      .format(DATE_TIME_FORMAT.REQUEST_DATE_TIME);
    tDate = moment().endOf("month").format(DATE_TIME_FORMAT.REQUEST_DATE_TIME);
  } else if (filterCriteria == DATE_FILTER_CRITERIA.CURRENT_YEAR) {
    fDate = moment().startOf("year").format(DATE_TIME_FORMAT.REQUEST_DATE_TIME);
    tDate = moment().endOf("year").format(DATE_TIME_FORMAT.REQUEST_DATE_TIME);
  } else if (filterCriteria == DATE_FILTER_CRITERIA.PREVIOUS_YEAR) {
    fDate = moment()
      .subtract(1, "year")
      .startOf("year")
      .format(DATE_TIME_FORMAT.REQUEST_DATE_TIME);
    tDate = moment()
      .subtract(1, "year")
      .endOf("year")
      .format(DATE_TIME_FORMAT.REQUEST_DATE_TIME);
  } else if (filterCriteria == DATE_FILTER_CRITERIA.CUSTOM) {
    fDate = from_date;
    tDate = to_date;
  } else if (filterCriteria == DATE_FILTER_CRITERIA.TODAY) {
    fDate = new Date().setDate(today.getDate());
  } else if (filterCriteria == DATE_FILTER_CRITERIA.YESTERDAY) {
    fDate = tDate = new Date().setDate(today.getDate() - 1);
  } else {
    tDate = "";
  }
  return { fDate, tDate };
}

export const getDateBasedOnCriteria = (
  filterCriteria: string,
  from_date?: Moment | string,
  to_date?: Moment | string,
  timezone?: string
) => {
  const _timezone = timezone;
  const filteredDates = getDatesAsPerSelectedCriteria(filterCriteria, from_date, to_date);
  let fDate = filteredDates.fDate;
  let tDate = filteredDates.tDate;

  let fromDate = fDate
    ? moment(fDate).format(DATE_TIME_FORMAT.REQUEST_DATE_TIME)
    : "";

  tDate = tDate ? moment(tDate).format(DATE_TIME_FORMAT.REQUEST_DATE_TIME) : "";

  if (_timezone) {
    if (fromDate) {
      fromDate = moment.tz(fromDate, _timezone).utc()
        .format(DATE_TIME_FORMAT.REQUEST_DATE_TIME);
    }

    if (tDate) {
      tDate = moment.tz(tDate, _timezone).utc()
        .format(DATE_TIME_FORMAT.REQUEST_DATE_TIME);
    }
  } else {
    if (fromDate) {
      const utcTimestamp = moment.utc(fromDate);
      fromDate = utcTimestamp
        .format(DATE_TIME_FORMAT.REQUEST_DATE_TIME);
    }

    if (tDate) {
      const utcTimestamp = moment.utc(tDate);
      tDate = utcTimestamp
        .format(DATE_TIME_FORMAT.REQUEST_DATE_TIME);
    }
  }

  return {
    fromDate,
    tDate,
  };
};

export const getTimezoneDateTime = (fromDate?: string, toDate?: string, timezone?: string) => {
  let _fromDate = fromDate;
  let _toDate = toDate;
  if (timezone) {
    if (fromDate) {
      _fromDate = moment.tz(fromDate, timezone).utc()
        .format(DATE_TIME_FORMAT.REQUEST_DATE_TIME);
    }

    if (toDate) {
      _toDate = moment.tz(toDate, timezone).utc()
        .format(DATE_TIME_FORMAT.REQUEST_DATE_TIME);
    }
  }

  return {
    fromDate: _fromDate,
    toDate: _toDate,
  };
}

export const convertDateTimeInLocalFormat = (timestamp: Moment, timezone?: string) => {
  //const _timezone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const _timezone = timezone;
  const utcTimestamp = moment.utc(timestamp);
  if (_timezone) {
    return utcTimestamp
      .clone()
      .tz(_timezone)
      .format(DATE_TIME_FORMAT.DATE_TIME);
  }
  return utcTimestamp.format(DATE_TIME_FORMAT.DATE_TIME);
};

export const getRoundOffValues = (value: number) => {
  const decimalValue = getLocalStorageSystemConfig(SYSTEM_CONFIG_KEYS.DECIMAL_VALUE);
  if (!isNaN(Number(decimalValue))) {
    return Number(value).toFixed(Number(decimalValue));
  }
  return value;
}

export const getLocalStorageSystemConfig = (key: string) => {
  const systemConfig = localStorage.getItem(LOCAL_STORAGE_KEYS.SYSTEM_CONFIG);
  if (systemConfig) return JSON.parse(systemConfig)[key];
  return null;
}

export const getDateDifference = (destination: string, fromDate?: string, toDate?: string) => {
  if (fromDate && toDate) {
    switch (destination) {
      case DATE_DIFF_TYPE.MILLI_SECONDS:
        return moment(toDate).diff(moment(fromDate), 'milliseconds');
      case DATE_DIFF_TYPE.SECONDS:
        return moment(toDate).diff(moment(fromDate), 'seconds');
      case DATE_DIFF_TYPE.MINUTES:
        return moment(toDate).diff(moment(fromDate), 'minutes');
      case DATE_DIFF_TYPE.HOURS:
        return moment(toDate).diff(moment(fromDate), 'hours');
      case DATE_DIFF_TYPE.DAY:
        return moment(toDate).diff(moment(fromDate), 'days');
      case DATE_DIFF_TYPE.MONTH:
        return moment(toDate).diff(moment(fromDate), 'months');
      case DATE_DIFF_TYPE.YEAR:
        return moment(toDate).diff(moment(fromDate), 'years');
    }
  }
  return undefined;
}

export const removeChannelTypeFromChannel = (item: string) => {
  return item.replace(/ *\([^)]*\) */g, "")
}

export const splitChannelTypeFromChannel = (specificOptionList: string[], raw_meter_channel_name: string) => {
  const splittedChannels: string[] = raw_meter_channel_name ? raw_meter_channel_name.split(',') : [];
  const displayedChannel: string[] = [];

  if (splittedChannels && splittedChannels.length) {
    splittedChannels.forEach(option => {
      const filteredUOMChannel = specificOptionList.find(item => removeChannelTypeFromChannel(item).toLowerCase() === option.toLowerCase());
      if (filteredUOMChannel) {
        displayedChannel.push(filteredUOMChannel);
      }
    });
  }
  const text = displayedChannel.join(',');
  return text;
}

export const isOnlyNEM12Permission = () => {
  const abilities = localStorage.getItem(LOCALSTORAGE_KEY.ABILITIES);
  const Abilities = abilities ? JSON.parse(abilities) : [];
  if (Abilities.length === 1) {
    return Abilities.find((item: { action: string; }) => item.action === 'NEM12View')
  }
  return false;
}

export const REDIRECT_ERROR_CODES = {
  FORGOT_PASSWORD: "AADB2C90118",
  LOGIN: "AADB2C90091",
};

export const devBlobURL = process.env.REACT_APP_NEM12_CSV_BLOB_URL;

export const logosBlobURL = process.env.REACT_APP_LOGOS_BLOB_URL;

export const DELAYED_API_SECONDS = 20 * 1000;

export const DURABLE_FUNCTION_RUNTIME_STATUS = {
  COMPLETED: 'Completed',
  FAILED: 'Failed',
  RUNNING: 'Running'
}

export const prepareBillingURL = (sessionId: string) => {
  return `${devBlobURL}/${sessionId}/${sessionId}-Billing.json`
}

export const prepareSummaryURL = (sessionId: string) => {
  return `${devBlobURL}/${sessionId}/${sessionId}-Summary.json`
}

//export const NEM12DurableFnURL = 'https://dev-ai-meter-data.azurewebsites.net/api/nem12-durable';
export const NEM12DurableFnURL = `${process.env.REACT_APP_API_URL}/meter-data/nem12-durable`;

export const loggedUserIsAdmin = () => {
  const decodeToken = decodedJwt();
  if (decodeToken && decodeToken.payload && decodeToken.payload.userData) {
    const userData = JSON.parse(decodeToken.payload.userData);
    return userData.is_admin ?? false;
  }
  return false;
}

export const getDefaultChartOption = (maxY: number, maxX: number) => {
  return {
    maintainAspectRatio: false,
    legend: {
      display: true,
      labels: {
        fontFamily: "Nunito Sans, sans-sarif",
        fontColor: "#8898aa",
      },
    },
    plugins: {
      zoom: {
        limits: {
          y: {
            min: 0,
            max: maxY,
            minRange: 1,
          },
          y1: {
            min: 0,
            max: maxX,
            minRange: 1,
          },
        },
        pan: {
          enabled: true,
          mode: 'xy',
          modifierKey: 'ctrl',
        },
        zoom: {
          wheel: { enabled: true, modifierKey: 'alt' },
          pinch: { enabled: true },
          mode: 'xy',
        },
      } as ZoomPluginOptions,
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
}

export const getSelect2CSS = (customizer?: any) => {
  let isDarkMode = false;

  let menuList = (base: any) => ({
    ...base
  });
  let control = (base: any, state: { isFocused: any; }) => ({
    ...base,
  })

  if (customizer) {
    isDarkMode = customizer.isDark;
    if (isDarkMode) {
      menuList = (base: any) => ({
        ...base,
        background: DARK_THEME_COLOR.BG,
        color: DARK_THEME_COLOR.CO
      });
      control = (base: any, state: { isFocused: any; }) => ({
        ...base,
        background: DARK_THEME_COLOR.CONTROL_BG,
        borderColor: DARK_THEME_COLOR.CONTROL_BORDER_COLOR
      })
    }
  }


  return {
    control,
    menuList,
    clearIndicator: (base: any, state: any) => ({
      ...base,
      cursor: "pointer",
    })
  }
}