import React, { Suspense } from "react";

// project imports
import Loader from "./Loader";

// ===========================|| LOADABLE - LAZY LOADING ||=========================== //
import { createBrowserHistory } from "history";

const History = createBrowserHistory({ basename: "/" });

const Loadable = (Component) => (props) =>
(
  <Suspense>
    <Component {...props} history={History} />
  </Suspense>
);

export default Loadable;
