import {
  LOGO_BG,
  NAVBAR_BG,
  SIDEBAR_BG,
  THEME,
  DIRECTION,
  SIDEBAR_POSITION,
  HEADER_POSITION,
  LAYOUT,
  SIDEBAR_TYPE,
  PERMISSIONS,
  ASSIGNED_PERMISSIONS,
  ABILITIES,
  SELECTED_TIMEZONE,
  SELECTED_DASHBOARD_FILTER,
  USER_SETTING
} from "../constants/";

const INIT_STATE = {
  activeDir: "ltr",
  activeThemeLayout: "vertical",
  activeTheme: localStorage.getItem("setTheme") || "light",
  activeSidebarType: "full",
  activeLogoBg: localStorage.getItem("setLogoBg") || "skin6",
  activeNavbarBg: localStorage.getItem("setNavbarBg") || "skin1",
  activeSidebarBg: localStorage.getItem("setSidebarBg") || "skin6",
  activeSidebarPos: "fixed",
  activeHeaderPos: "fixed",
  activeLayout: "full",
  permissions: null,
  assignedPermissions: null,
  abilities: [],
  selectedTimezone: null,
  selectedFilterParams: null,
  userSetting: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGO_BG:
      return {
        ...state,
        activeLogoBg: action.payload,
      };
    case NAVBAR_BG:
      return {
        ...state,
        activeNavbarBg: action.payload,
      };
    case SIDEBAR_BG:
      return {
        ...state,
        activeSidebarBg: action.payload,
      };
    case THEME:
      return {
        ...state,
        activeTheme: action.payload,
      };
    case DIRECTION:
      return {
        ...state,
        activeDir: action.payload,
      };
    case SIDEBAR_POSITION:
      return {
        ...state,
        activeSidebarPos: action.payload,
      };
    case HEADER_POSITION:
      return {
        ...state,
        activeHeaderPos: action.payload,
      };
    case LAYOUT:
      return {
        ...state,
        activeLayout: action.payload,
      };
    case SIDEBAR_TYPE:
      return {
        ...state,
        activeSidebarType: action.payload,
      };
    case PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      };
    case ASSIGNED_PERMISSIONS:
      return {
        ...state,
        assignedPermissions: action.payload,
      };
    case ABILITIES:
      return {
        ...state,
        abilities: action.payload,
      };
    case SELECTED_TIMEZONE:
      return {
        ...state,
        selectedTimezone: action.payload,
      };
    case SELECTED_DASHBOARD_FILTER:
      return {
        ...state,
        selectedFilterParams: action.payload,
      };
      case USER_SETTING:
        return {
          ...state,
          userSetting: action.payload,
        };
    default:
      return state;
  }
};
