import { ITimezone } from "../types";
import { USER_SETTING } from "../redux/constants";

export const DEFAULT_VALUES = {
  PAGE_INDEX: 1,
  PAGE_SIZE: 10,
  OFFSET: 0,
};

export const ACTION = {
  VIEW: "VIEW",
  EDIT: "EDIT",
};

export const MODULE_PERMISSION = {
  USER: {
    VIEW: "UserView",
    ADD: "UserAdd",
    EDIT: "UserEdit",
    DELETE: "UserDelete",
  },
  USER_ROLE: {
    VIEW: "UserRoleView",
    ADD: "UserRoleAdd",
    EDIT: "UserRoleEdit",
    DELETE: "UserRoleDelete",
  },  
  METER: {
    VIEW: "MeterView",
    ADD: "MeterAdd",
    EDIT: "MeterEdit",
    DELETE: "MeterDelete",
    VIEW_AUDIT: "ViewAuditLog",
  },
  METERREADING: {
    VIEW: "MeterReadingView",
    EDIT: "MeterReadingUpdate",
    DELETE: "MeterReadingDelete",
  },
  METER_GROUP: {
    VIEW: "MeterGroupView",
    ADD: "MeterGroupAdd",
    EDIT: "MeterGroupEdit",
    DELETE: "MeterGroupDelete",
  },
  SITE_MANAGEMENT: {
    VIEW: "SiteManagementView",
    Add: "SiteManagementAdd",
    EDIT: "SiteManagementEdit",
    DELETE: "SiteManagementDelete",
  },
  CONTACT: {
    VIEW: "ContactManagementView",
    ADD: "ContactManagementAdd",
    EDIT: "ContactManagementEdit",
    DELETE: "ContactManagementDelete",
  },
  METER_STATUS: {
    VIEW: "MeterStatusView",
    ADD: "MeterStatusAdd",
    EDIT: "MeterStatusEdit",
    DELETE: "MeterStatusDelete",
  },
  CONFIGURATION: {
    CONFIGURE_METER_CHANNEL: "ConfigureMeterChannel",
    VIEW_CUSTOMER_NAME: "ViewCustomerName",
  },
  NEM12: {
    VIEW: "View",
  },
  SCHEDULE_LINK: {
    VIEW: "MeterScheduleLinkView",
    ADD: "MeterScheduleLinkAdd",
    EDIT: "MeterScheduleLinkEdit",
    DELETE: "MeterScheduleLinkDelete"
  },
  UNPROVISIONED_METER: {
    VIEW: "UnprovisionedMeter",
  },
  LOGO_UPLOAD : {
    ADD: "LogoUpload",
  },
};

export const ROUTER = {
  DASHBOARD: "/dashboard",
  METER_GROUP: "/master/meter-groups",
  SITE_MANAGEMENT: "/master/site-management",
  USER: "/master/users",
  METER: "/master/meter/meters",
  ROLE: "/master/roles",
  CONTACT: "/master/contacts",
  METER_DATA_AUDIT: "/master/meter/data-audit",
  METER_STATUS: "/master/meter/status",
  UNPROVISIONED_METER: "/master/meter/unprovisioned",
  DASHBOARD_CONFIGURATION: "/master/dashboard-configuration",
  NEM12: "/nem12",
  PROFILE:"/profile"
};

export const API_RESPONSE_CODE = {
  SUCCESS: 200,
};

export const API_ERROR_MESSAGE = "Internal server error"

export const ACTION_HEADER = "ACTION";

export const ACTION_GET_FINAL_READS = "GET FINAL READS";

export const GRID_ACTION_CSS_CLASS = "grid-action";

export const CHECKBOX = "checkbox";

export const DATE_FILTER_CRITERIA = {
  TODAY: "Today",
  YESTERDAY: "Yesterday",
  LAST_MONTH: "Last Month",
  CURRENT_MONTH: "Current Month",
  LAST_30_DAYS:"Last 30 Days",
  LAST_3_MONTHS:"Last 3 Months",
  CURRENT_YEAR: "Current Year",
  PREVIOUS_YEAR: "Last Year",
  CUSTOM: "Custom",
};

export const INTERVAL = [
  {
    name: "1 min",
    value: "1 min",
  },
  {
    name: "5 min",
    value: "5 min",
  },
  {
    name: "10 min",
    value: "10 min",
  },
  {
    name: "15 min",
    value: "15 min",
  },
  {
    name: "30 min",
    value: "30 min",
  },
  {
    name: "1 hour",
    value: "1 hour",
  },
  {
    name: "6 hour",
    value: "6 hour",
  },
  {
    name: "1 day",
    value: "1 day",
  },
  {
    name: "1 month",
    value: "1 month",
  },
  {
    name: "1 year",
    value: "1 year",
  },
];

export const SHOW_INTERVAL = "Interval";

export const SHOW_CUMULATIVE = "Cumulative";

export const meterTypeIcons = [
  {
    type: "water",
    icon: "mdi mdi-water-pump",
  },
  {
    type: "gas",
    icon: "mdi mdi-gas-station",
  },
  {
    type: "electricity",
    icon: "mdi mdi-power-plug",
  },
  {
    type: "thermal",
    icon: "icon-energy",
  },
];

export const DATE_TIME_FORMAT = {
  DATE: "DD-MM-yyyy",
  DATE_TIME: "DD-MM-YYYY HH:mm",
  REQUEST_DATE: "YYYY-MM-DD",
  REQUEST_DATE_TIME: "YYYY-MM-DD HH:mm",
};

export const Australia = 'Australia/';

export const TABS_CONFIGURATION = {
  DASHBOARD: "Dashboard",
  FINAL_READING: "FinalReading",
  VIEW_READING: "ViewReading",
  SYSTEM_CONFIGURATION: "SystemConfiguration",
  AGGREGATION: "Aggregation",
};

export const LOCAL_STORAGE_KEYS = {
  SYSTEM_CONFIG: "SYSTEM_CONFIG",
};

export const SYSTEM_CONFIG_KEYS = {
  DECIMAL_VALUE: "DECIMAL_VALUE",
  AI_SIGN_IN:"AI_SIGN_IN",
  AI_CONTACT:"AI_CONTACT"
};

export const AGGREGATION_FUNCTION = {
  AVG: "AVG",
  LASTVALUE_FIRSTVALUE: "LastValue – FirstValue",
  MAX: "MAX",
  MIN: "MIN",
  SUM: "SUM",
};

export const WIDGET_TYPE = {
  CHART: "Chart",
  VALUE_CARD: "Value Card",
};

export const DATE_DIFF_TYPE = {
  MILLI_SECONDS: "millisecond",
  SECONDS: "second",
  HOURS: "hour",
  MINUTES: "minute",
  DAY: "day",
  MONTH: "month",
  YEAR: "year",
};

export const LAYOUT_POSITION = {
  DASHBOARD:"Dashboard",
  ALL_SITES:"All Sites",
  SINGLE_SITE:"Single Site",
  SINGLE_PROPERTY:"Single Property",
  METER_SUMMARY:"Meter Summary"
}

export const AGGREGATION_WIDTH = [
  "1","2","3","4","5","6","7","8","9","10","11","12",
];

export const AGGREGATION_TYPE = {
  CUMULATIVE: "Cumulative",
  INSTANTANEOUS: "Instantaneous",
  INTERVAL: "Interval"
};

export const AGGREGATION_GROUP_BY = [
  {
    label: "Date",
    value: "date",
  },
  {
    label: "Day of week",
    value: "day_of_week",
  },
  {
    label: "Meter group",
    value: "meter_group",
  },
  {
    label: "Property name",
    value: "property_name",
  },
  {
    label: "Site name",
    value: "site_name",
  },
 
];

export const TOKEN_EXPIRY_DIFF = 120;

export const LOCALSTORAGE_KEY = {
  ABILITIES: 'abilities',
  PERMISSION: 'permission'
}

export const SCHEDULE_LINK_LOCATION = {
  DASHBOARD: 'DASHBOARD',
  SITES: 'SITES'
}

export const ADMIN = "Admin";

export const METER_GROUP = "MeterGroup";

export const DARK_THEME_COLOR = {
  BG: '#323743',
  CO: '#b2b9bf',
  CONTROL_BG: "#001e3c",
  CONTROL_BORDER_COLOR: '#4d6277'
  }

export const  REASON_CODE = "79";

export const  REASON_CODE_COLOUR = "#8DAB06";

export const LOGO_TYPE_DB = {
  BIG_LOGO: 'big',
  SMALL_LOGO: 'small'
}

export const LOGO_TYPE = {
  BIG_LOGO: 'bigLogo',
  SMALL_LOGO: 'smallLogo'
}

export const barGraphColor = () => {
  const localUserSetting = localStorage.getItem(USER_SETTING);
  let returnColor = "#3491e3";
  if (localUserSetting) {
    const userSetting = JSON.parse(localUserSetting);
    if (userSetting) returnColor = userSetting.chartColor;
  }
  return returnColor;
};